import React, { useState, useEffect, useMemo, memo } from 'react'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import { useTheme, styled, css } from '@astronautsid/wpe-astro-ui/theme'
import InputLabel from '@mui/material/InputLabel'

import { MenuItem } from '../MenuItem'
import { NoData, RenderValue, RenderMultipleValue, SelectAll, SelectNone } from './components'

type OptionObjType = {
  [key: string]: unknown
}

type OptionNameType = {
  [key: string]: string
}

export type SelectPropsType =
  | Omit<MuiSelectProps, 'value' | 'onChange' | 'error'> &
      (
        | {
            multiple: true
            value: string[] | OptionObjType[]
          }
        | {
            multiple?: false
            value: string | OptionObjType | null
          }
      ) & {
        disableAutoSelectedOnClick?: boolean
        options: OptionObjType[] | string[]
        displayKey?: string
        multipleProps?: {
          valueType?: 'chip' | 'total'
          suffixTotal?: string
          hideSelectAll?: boolean
          selectAllText?: string
        }
        supportText?: string
        errorMessage?: string
        onClear?: () => void
        onChange?: (value: never) => void
        onSearch?: (value: string) => void
        CustomRenderOption?: React.FC<{ option: never }>
        dataNotFoundText?: string
      }

export const generateOptions = (options: SelectPropsType['options']) => {
  const isArrOfStr = typeof options[0] === 'string'
  return isArrOfStr ? options.map((val) => ({ name: val })) : (options as OptionObjType[])
}

export const generateValue = (
  val: SelectPropsType['value'],
  displayKey: string,
): string | string[] => {
  const isArrayOfObject = Array.isArray(val) && typeof val[0] === 'object'
  const isObjectKeyExist = typeof val === 'object' && (val as OptionNameType)?.[displayKey]

  if (isArrayOfObject) return (val as OptionNameType[]).map((item) => item[displayKey])
  if (isObjectKeyExist) return (val as OptionNameType)[displayKey]

  return (val || '') as string | string[]
}

export const SelectStyled = styled(MuiSelect)<MuiSelectProps>`
  ${({ theme }) => css`
    & .MuiInputBase-input {
      background-color: ${theme.palette.bgColor.light} !important;
      color: ${theme.palette.textColor.primaryDark};
      font-size: 14px;
      padding: 12px 42px 12px 16px;
      ::placeholder {
        line-height: 25px;
        color: ${theme.palette.neutral['neutral-7']};
      }
    }
    & .Mui-disabled {
      background-color: ${theme.palette.bgColor.disable};
    }
    & .MuiSelect-icon {
      right: 10px;
    }
  `}
`

export const StyledFormControl = styled(FormControl)<FormControlProps>`
  ${({ theme }) => css`
    & .MuiInputBase-root {
      border-color: ${theme.palette.neutral['neutral-4']};
      border-radius: 8px;
    }

    & .MuiInputLabel-root {
      background: ${theme.palette.bgColor.light};
      font-size: 14px;
      left: 2px;
      top: -1px;
    }
  `}
`

const Select = ({
  required,
  supportText,
  errorMessage,
  fullWidth,
  children,
  placeholder,
  value,
  onClear,
  disableAutoSelectedOnClick,
  options = [],
  displayKey = 'name',
  multipleProps,
  onChange,
  onSearch,
  disabled,
  multiple,
  dataNotFoundText,
  CustomRenderOption,
  label,
  ...rest
}: SelectPropsType) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<string | string[]>(generateValue(value, displayKey))

  const isAllSelected = !!multiple && options.length > 0 && selected.length === options.length
  const isIndeterminate = !!multiple && selected.length > 0 && selected.length < options.length

  const listOptions = useMemo(() => generateOptions(options), [options])

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleCheckedMultiple = (key: string) => {
    let checked: string[] = []
    const val = [...(selected as string[])]
    const isExist = val.some((item) => item === key)

    if (key === 'ALL') {
      checked =
        val.length === options.length
          ? []
          : listOptions.map((item) => (item as OptionNameType)[displayKey])
    } else if (isExist) {
      checked = val.filter((item) => item !== key)
    } else {
      checked = [...val, key]
    }

    setSelected(checked)

    if (typeof onChange === 'function') {
      const isArrOfStr = typeof options[0] === 'string'
      const result = isArrOfStr
        ? (options as string[]).filter((opts) => checked.includes(opts))
        : (options as OptionNameType[]).filter((opts) => checked.includes(opts[displayKey]))

      onChange(result as never)
    }
  }

  const handleRemoveChip = (key: string) => {
    handleCheckedMultiple(key)
  }

  const handleSelected = (val: string, item?: never) => {
    if (multiple) {
      handleCheckedMultiple(val)
    } else {
      if (!disableAutoSelectedOnClick) setSelected(val)
      handleClose()

      if (typeof onChange === 'function') {
        if (item) {
          const isArrOfStr = typeof options[0] === 'string'
          const result = isArrOfStr ? item[displayKey] : item

          onChange(result)
        } else {
          onChange(null as never)
        }
      }
    }
  }

  const handleClear = () => {
    handleClose()
    setSelected(multiple ? [] : '')
    if (onClear) onClear()
    else if (onChange) onChange((multiple ? [] : '') as never)
  }

  useEffect(() => {
    setSelected(generateValue(value, displayKey))
  }, [displayKey, value])

  return (
    <StyledFormControl error={!!errorMessage} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <SelectStyled
        displayEmpty
        disabled={disabled}
        multiple={multiple}
        value={selected}
        label={label}
        renderValue={(val) =>
          multiple ? (
            <RenderMultipleValue
              value={val as string[]}
              placeholder={placeholder}
              onDelete={handleRemoveChip}
              multipleProps={multipleProps}
            />
          ) : (
            <RenderValue value={val as string} placeholder={placeholder} />
          )
        }
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        {...rest}
      >
        {multiple && !multipleProps?.hideSelectAll && (
          <SelectAll
            sx={{ display: options.length === listOptions.length ? 'flex' : 'none' }}
            selectAllText={multipleProps?.selectAllText}
            checked={isAllSelected}
            indeterminate={isIndeterminate}
            onClick={() => handleSelected('ALL')}
          />
        )}

        {!required && <SelectNone onClick={handleClear} />}

        {listOptions.map((item, index) => {
          const name = (item as OptionNameType)[displayKey]
          const key = `${name}-${index}`

          return (
            <MenuItem
              key={key}
              onClick={() => handleSelected(name, item as never)}
              sx={{ bgcolor: name === selected ? theme.palette.bgColor.lightPrimary : 'unset' }}
            >
              <ListItemText>
                {CustomRenderOption ? (
                  <CustomRenderOption option={listOptions[index] as never} />
                ) : (
                  <Typography variant="paragraph-small">{name}</Typography>
                )}
              </ListItemText>
              {multiple && (
                <ListItemIcon>
                  <Checkbox checked={selected.includes(name)} />
                </ListItemIcon>
              )}
            </MenuItem>
          )
        })}

        {!listOptions.length && <NoData dataNotFoundText={dataNotFoundText} />}
      </SelectStyled>

      {(!!errorMessage || supportText) && (
        <FormHelperText>{errorMessage || supportText}</FormHelperText>
      )}
    </StyledFormControl>
  )
}

export const SelecteBase = MuiSelect
export type SelectBaseType = MuiSelectProps

export default memo(Select)
