'use client'

import { useEffect } from 'react'

import {
  getMixpanelInstance,
  getScreenNameMetaFromDocument,
  trackPageView,
  mixpanelInit,
} from 'lib/mixpanelTracker'
import { usePathname, useSearchParams } from 'next/navigation'

type MixPanelPropsType = {
  children?: React.ReactNode
}

mixpanelInit()

const MixPanel = ({ children }: MixPanelPropsType) => {
  const pathName = usePathname()
  const searchQuery = useSearchParams()

  useEffect(() => {
    if (getMixpanelInstance()) {
      const screenName = getScreenNameMetaFromDocument()

      trackPageView({
        screenName,
      })
    }
  }, [pathName, searchQuery])

  return children
}

export default MixPanel
