import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'

export type SelectNonePropsType = {
  onClick: React.MouseEventHandler<HTMLLIElement>
}

const SelectNone = ({ onClick }: SelectNonePropsType) => (
  <MenuItem value="" onClick={onClick}>
    <ListItemText>
      <Typography variant="paragraph-small">
        <em>None</em>
      </Typography>
    </ListItemText>
  </MenuItem>
)

export default SelectNone
