import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'

import { styled, css } from '@astronautsid/wpe-astro-ui/theme'

export type TextFieldPropsType = Omit<MuiTextFieldProps, 'error'> & {
  supportText?: string
  errorMessage?: string
}

const TextFieldBase = styled(MuiTextField)<TextFieldPropsType>`
  ${({ theme }) => css`
    & .MuiInputBase-root {
      background-color: ${theme.palette.bgColor.light};
      border-radius: 8px;
    }
    & .MuiInputBase-input {
      padding: 12px 16px;
      color: ${theme.palette.textColor.primaryDark};

      ::placeholder {
        color: ${theme.palette.neutral['neutral-7']};
      }
    }
    & .MuiOutlinedInput-root {
      border: none;
      fieldset {
        border-radius: 8px;
      }
    }
    & .Mui-focused {
      fieldset {
        border-width: 1px !important;
      }
    }
    & .Mui-disabled {
      background-color: ${theme.palette.bgColor.disable};
    }

    & .MuiInputBase-multiline {
      padding: 0px;
    }

    & .MuiInputLabel-root,
    & .MuiInputBase-input {
      font-size: 14px;
    }

    & .MuiInputLabel-root {
      color: ${theme.palette.textColor.disable};
      top: -4px;
      left: 2px;
    }

    & .MuiFormHelperText-root {
      margin-left: 16px;
    }
  `}
`

const TextField = ({ supportText, errorMessage, ...rest }: TextFieldPropsType) => (
  <TextFieldBase error={!!errorMessage} helperText={errorMessage || supportText} {...rest} />
)

export default TextField
