'use client'

import { useTheme } from '@astronautsid/wpe-astro-ui/theme'
import { Toaster, ToastBar } from 'react-hot-toast'

const CustomToaster = () => {
  const theme = useTheme()

  return (
    <Toaster
      toastOptions={{
        style: {
          color: `${theme.palette.textColor.primaryLight}`,
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 600,
          padding: '8px 2px',
          borderRadius: '8px',
          width: '100%',
        },
        success: {
          style: {
            background: `${theme.palette.bgColor.primaryDark}`,
          },
        },
        error: {
          style: {
            background: `${theme.palette.bgColor.red}`,
          },
        },
        duration: 6000,
      }}
      containerStyle={{
        bottom: '90px',
      }}
      position="bottom-center"
    >
      {(t) => (
        <ToastBar toast={t} position="bottom-center">
          {({ message }) => <div>{message}</div>}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default CustomToaster
