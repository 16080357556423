import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import { SxProps } from '@mui/material/styles'

export type SelectAllPropsType = MenuItemProps & {
  selectAllText?: string
  checked: boolean
  indeterminate: boolean
  onClick: React.MouseEventHandler<HTMLLIElement>
  sx?: SxProps
}

const SelectAll = ({ selectAllText, checked, indeterminate, onClick, sx }: SelectAllPropsType) => (
  <MenuItem sx={sx} onClick={onClick}>
    <ListItemText>
      <Typography variant="headline-small">{selectAllText || 'Select All'}</Typography>
    </ListItemText>
    <ListItemIcon>
      <Checkbox checked={checked} indeterminate={indeterminate} />
    </ListItemIcon>
  </MenuItem>
)

export default SelectAll
