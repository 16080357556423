import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'

export type NoDataPropsType = {
  dataNotFoundText?: string
}
const NoData = ({ dataNotFoundText }: NoDataPropsType) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="200px"
  >
    <Box display="flex" width="100%" p={3} textAlign="center" justifyContent="center">
      <Typography variant="body-largeStrong">{dataNotFoundText || 'No Data'}</Typography>
    </Box>
  </Box>
)
export default NoData
