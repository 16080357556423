import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem'
import { styled, css } from '@astronautsid/wpe-astro-ui/theme'

export type MenuItemPropsType = MuiMenuItemProps

const MenuItem = styled(MuiMenuItem)<MenuItemPropsType>`
  ${({ theme }) => css`
    height: 42px;
    padding: 16px;
    color: ${theme.palette.textColor.primaryDark};
    border-bottom: 1px solid ${theme.palette.neutral['neutral-2']};
    :last-child {
      border-bottom: unset;
    }
  `}
`

export default MenuItem
