'use client'

import { useEffect, useState } from 'react'
import {
  initDatadogLog,
  setUserData as setUserLogCtx,
} from '@astronautsid/wpe-utils/helpers/datadog'
import { useShallow } from 'zustand/react/shallow'

import useAuthStore, { type AuthSliceType } from 'store/auth'
import useUserStore from 'store/user'
import {
  ENABLE_DATADOG,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  DATADOG_SESSION_SAMPLE_RATE,
  APP_NAME,
  APP_VERSION,
  ENV,
} from 'config/constants'
import { useSearchParams } from 'next/navigation'

const selectAuthStore = ({ auth }: AuthSliceType) => ({
  auth,
})

const useBrowserLog = () => {
  const { auth: authWebview } = useAuthStore(useShallow(selectAuthStore))
  const { user: currentUser, isLoggedIn } = useUserStore((state) => state)

  const [hasInitDatadog, setHasInitDatadog] = useState(false)

  const searchParams = useSearchParams()

  const isWebview = searchParams.get('webview') === 'true'

  useEffect(() => {
    if (!hasInitDatadog) {
      return
    }

    /**
     * Set User ctx for webview user
     */
    if (isWebview && authWebview.isAuthenticated) {
      const { user } = authWebview

      setUserLogCtx({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        isLoggedIn: authWebview.isAuthenticated,
      })

      return
    }

    /**
     * Set User ctx for default user
     */
    setUserLogCtx({
      firstName: currentUser?.first_name || '',
      lastName: currentUser?.last_name || '',
      phoneNumber: currentUser?.phone_number || '',
      isLoggedIn,
    })
  }, [authWebview, hasInitDatadog, currentUser, isWebview, isLoggedIn])

  useEffect(() => {
    if (!ENABLE_DATADOG) {
      return
    }

    initDatadogLog({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      forwardErrorsToLogs: false,
      sessionSampleRate: Number(DATADOG_SESSION_SAMPLE_RATE),
      env: ENV === 'production' ? 'production' : 'development',
      service: APP_NAME,
      version: APP_VERSION,
    })

    setHasInitDatadog(true)
  }, [])
}

export default useBrowserLog
