'use client'

import { PropsWithChildren, createContext, useContext, useRef } from 'react'
import { StoreApi, useStore } from 'zustand'

import {
  createSessionStore,
  sessionStoreDefaultState,
  type SessionStateType,
  type UserSliceType,
} from './store'

export type SessionStoreProviderPropsType = PropsWithChildren<{
  initState: SessionStateType
}>

export const SessionStoreContext = createContext<StoreApi<UserSliceType> | null>(null)

export const SessionStoreProvider = ({ children, initState }: SessionStoreProviderPropsType) => {
  const storeRef = useRef<StoreApi<UserSliceType>>()

  if (!storeRef.current) {
    storeRef.current = createSessionStore({ ...sessionStoreDefaultState, ...initState })
  }

  return (
    <SessionStoreContext.Provider value={storeRef.current}>{children}</SessionStoreContext.Provider>
  )
}

export const useSessionStoreContext = () => useContext(SessionStoreContext)

const useSessionStore = <T,>(selector: (store: UserSliceType) => T): T => {
  const sessionStoreContext = useSessionStoreContext()

  if (!sessionStoreContext) {
    throw new Error(`useSessionStore must be use within SessionStoreProvider`)
  }

  return useStore(sessionStoreContext, selector)
}

export default useSessionStore
