import Typography from '@mui/material/Typography'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

export type RenderValueType = {
  value: string
  placeholder?: string
}

const RenderValue = ({ value, placeholder }: RenderValueType) => {
  const theme = useTheme()

  if (!value)
    return (
      <Typography
        sx={{
          color: !value ? theme.palette.textColor.placeholder : theme.palette.textColor.primaryDark,
        }}
        variant="paragraph-small"
      >
        {placeholder}
      </Typography>
    )

  return value
}

export default RenderValue
